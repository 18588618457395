@import "./../styles/variables";
@import "./../styles/mixins";

.home {
  position: relative;
  display: flex;
  min-height: 100vh;
  padding-bottom: 100px;
  .home-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s all;
    z-index: 2;
    h1 {
      position: relative;
      font-family: Spacegrotesk, sans-serif;
      font-size: 120px;
      font-weight: 700;
      line-height: 1.05;
      letter-spacing: -0.03em;
      color: $primaryColor;
      background-color: $primaryColor;
      background-image: linear-gradient(
        230deg,
        $secondaryColor 30%,
        $primaryColor 80%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
      &::after {
        content: "Software Developer";
        position: absolute;
        top: 97px;
        left: 305px;
        font-family: Karla, sans-serif;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 13.6px;
        color: #ddd;
        -webkit-text-fill-color: #ddd;
        text-transform: uppercase;
      }
    }
  }
  .dot-pattern {
    width: 200px;
    height: auto;
    position: absolute;
    left: 60%;
    bottom: 120px;
    margin-left: -100px;
    path {
      fill: #ffcdcf;
    }
  }
}

@media (max-width: 1023px) {
  .home .home-content h1::after {
    top: 224px;
    left: 8px;
    letter-spacing: 13.5px;
  }
}

@media (max-width: 767px) {
  .home .home-content h1 {
    font-size: 100px;
    &::after {
      top: 187px;
      left: 5px;
      font-size: 14px;
      letter-spacing: 10.9px;
    }
  }
}

@media (max-width: 539px) {
  .home .home-content h1 {
    font-size: 60px;
    &::after {
      top: 112px;
      left: 3px;
      font-size: 12px;
      letter-spacing: 4.3px;
    }
  }
}
