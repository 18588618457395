/* latin-ext */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  src: local("Karla"), local("Karla-Regular"),
    url("./assets/fonts/Karla-Regular-range-1.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  src: local("Karla"), local("Karla-Regular"),
    url("./assets/fonts/Karla-Regular-range-2.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  src: local("Karla Bold"), local("Karla-Bold"),
    url("./assets/fonts/Karla-Bold-range-1.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  src: local("Karla Bold"), local("Karla-Bold"),
    url("./assets/fonts/Karla-Bold-range-2.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Spacegrotesk";
  src: url("./assets/fonts/SpaceGrotesk-Regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Spacegrotesk";
  src: url("./assets/fonts/SpaceGrotesk-Medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Spacegrotesk";
  src: url("./assets/fonts/SpaceGrotesk-SemiBold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Spacegrotesk";
  src: url("./assets/fonts/SpaceGrotesk-Bold.woff") format("woff");
  font-weight: 700;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  height: 100%;
  scroll-behavior: smooth;
  scrollbar-width: none;
}
html::-webkit-scrollbar {
  display: none;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
a {
  text-decoration: none;
}
img,
svg {
  width: 100%;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
#root {
  min-width: 340px;
  max-width: 100%;
  overflow: hidden;
}
#overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 10;
}
