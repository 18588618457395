@mixin flex-center($flexDirection: row) {
  display: flex;
  flex-direction: $flexDirection;
  align-items: center;
  justify-content: center;
}

@mixin social-icon($width: 25px) {
  svg {
    width: $width;
    transition: all 0.2s ease;
  }
}

@mixin nav-menu-hover-effect {
  a:hover {
    color: $activeMenuColor;
  }
  svg:not(:hover) path {
    fill: $inactiveMenuColor !important;
  }
}

@mixin circle($size) {
  width: $size;
  min-width: $size;
  height: $size;
  border-radius: 50%;
}

@mixin circularBackground($size) {
  @include circle($size);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
