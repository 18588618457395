@import "./../styles/mixins";

.insta-feed {
  background-color: #000;
  padding-bottom: 10px;
  .feed-item {
    color: #f9f9f9;
    padding: 0 12px;
    margin-bottom: 10px;
  }
  .feed-item-author {
    display: flex;
    align-items: center;
    padding: 11px 0;
    .author-image {
      @include circularBackground(34px);
      margin-right: 10px;
    }
    .author-details {
      .author-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
      }
      .author-location {
        font-size: 12px;
        line-height: 13px;
        letter-spacing: 0.07px;
      }
    }
  }
  .feed-image {
    margin: 0 -12px;
  }
  .feed-actions {
    width: 100%;
    height: 45px;
    background: url(./../assets/images/instagram-feed-actions.jpg) #000
      no-repeat center;
    background-size: contain;
  }
  .feed-details {
    .liked-by {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.07px;
      margin-bottom: 5px;
    }
    .comment {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.1px;
      strong {
        margin-right: 2px;
      }
    }
  }
}
