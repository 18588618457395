.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

@media (max-width: 1240px) {
  .container {
    max-width: unset;
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  .container {
    padding: 0 20px;
  }
}

/* 
@media (max-width: 1024px) {
  .container {
    max-width: 600px;
  }
}
 */
