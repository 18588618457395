@import "./../styles/mixins";

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //   background-position: center;
  //   background-size: contain;
  //   background-image: url(./../assets/home-bg.png);
  //   background-attachment: fixed;
  // filter: invert(1);
  z-index: -1;
  background-color: #000;
}

.parallax-moon {
  position: absolute;
  top: 150px;
  left: 150px;
  width: 140px;
  height: 140px;
  background-image: url(./../assets/images/moon.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  z-index: 1;
}
.parallax-earth {
  position: absolute;
  bottom: -100%;
  width: 100%;
  height: 270px;
  background-image: url(./../assets/images/earth.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.stars,
.twinkling {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 0;
}

.stars {
  background: #000 url(./../assets/images/stars.png) repeat top center;
}

.twinkling {
  background: transparent url(./../assets/images/twinkling.png) repeat top
    center;
  animation: move-twink-back 200s linear infinite;
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
