@import "./../styles/variables";
@import "./../styles/mixins";

$contentPadding: 14px;

.sticky-top {
  position: sticky;
  top: 0;
  background-color: #121212;
  z-index: 1;
}

.instagram-mock-wrapper {
  position: relative;
  width: 100%;
  @include flex-center(column);
  margin: auto;

  .mobile-mockup {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 328px;
    height: 653px;
    background: url(./../assets/images/iphone-mockup.png) no-repeat center;
    background-size: contain;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 24px;
      width: 128px;
      height: 5px;
      background: #fff;
      border-radius: 100px;
      transform: translateX(-50%);
    }
    .status-bar {
      width: 288px;
      height: 30px;
      display: flex;
      justify-content: space-between;
      background: url(./../assets/images/iphone-mockup-status-bar.svg) no-repeat
        center;
      background-size: cover;
      border-radius: 60px 60px 0 0;
      padding: 4px 8px 0 14px;
      margin: 18px 0 0 19px;
      .time {
        width: 55px;
        font-size: 14px;
        color: #fff;
        text-align: right;
      }
      .right {
        display: flex;
        margin-top: 5px;
        .wifi-signal {
          width: 15px;
          height: 11px;
          background: url(./../assets/images/icons/wifi.svg) no-repeat center;
          background-size: contain;
          margin-right: 5px;
        }
        .batter-indicator {
          width: 24px;
          height: 11px;
          background: url(./../assets/images/icons/battery.svg) no-repeat center;
          background-size: contain;
        }
      }
    }
  }
  .content {
    width: 290px;
    height: 565px;
    overflow-y: auto;
    scroll-behavior: smooth;
    background-color: #121212;
    border-radius: 0 0 6px 6px;
    z-index: 1;
    padding: 0 $contentPadding 0;
    scrollbar-width: none;
    margin: 48px auto;
    &::-webkit-scrollbar {
      display: none;
    }

    .tool-bar {
      @extend .sticky-top;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      padding: 0 $contentPadding;
      margin: 0 (-$contentPadding);
      .back-icon {
        width: 10px;
        height: 18px;
        background: url(./../assets/images/icons/back.svg) no-repeat center;
        background-size: contain;
      }
      .hamburger-icon {
        width: 20px;
        height: 17px;
        background: url(./../assets/images/icons/menu.svg) no-repeat center;
        background-size: contain;
      }
    }

    .profile-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .profile-pic-wrap {
        width: 96px;
        min-width: 96px;
        height: 96px;
        border: 2px solid #48484a;
        border-radius: 50%;
        padding: 2px;
        img {
          border-radius: 50%;
        }
      }
      .stats {
        min-width: 60px;
        @include flex-center(column);
        color: #f9f9f9;
        span:nth-child(1) {
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          letter-spacing: -0.3px;
        }
        span:nth-child(2) {
          font-size: 13px;
          line-height: 16px;
          letter-spacing: -0.1px;
        }
      }
    }
    .bio-section {
      font-size: 12px;
      color: #f9f9f9;
      margin-bottom: 15px;
      .name {
        font-weight: 600;
        line-height: 14px;
        margin-bottom: 5px;
      }
      .bio span {
        display: flex;
        align-items: center;
        line-height: 22px;
        color: #f9f9f9bb;
        &::before {
          content: "";
          display: inline-flex;
          width: 14px;
          height: 14px;
          background-size: 16px;
          background-repeat: no-repeat;
          margin-right: 7px;
        }
        &:nth-child(1)::before {
          background-image: url(./../assets/images/icons/briefcase.png);
        }
        &:nth-child(2)::before {
          background-image: url(./../assets/images/icons/love.png);
        }
        &:nth-child(3)::before {
          background-image: url(./../assets/images/icons/map.png);
        }
        a {
          color: #f9f9f9bb;
          margin-left: 3px;
        }
      }
    }
    .layout-selection {
      @extend .sticky-top;
      display: flex;
      height: 44px;
      border-top: 1px solid #3c3c434a;
      margin: 0 (-$contentPadding);
      span {
        width: 50%;
        @include flex-center;
        color: #fff;
        cursor: pointer;
        &.active {
          border-bottom: 1px solid #f9f9f9;
        }
      }
    }

    .posts-section {
      display: flex;
      overflow: hidden;
      margin: 0 (-$contentPadding);
      .tech,
      .project {
        flex: 0 0 100%;
        transition: 0.4s margin;
      }
      .tech img {
        width: 33%;
        height: 95px;
        background-color: #000;
        filter: saturate(1.5);
        padding: 20px;
        border-left: 1px solid #fff6;
        border-bottom: 1px solid #fff6;
      }
      .project img {
        margin-bottom: 2px;
      }
    }
    .text-contact-me {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 2px;
      color: #b4b2c5;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    .text-lets-chat {
      font-size: 38px;
      font-weight: 600;
      line-height: 1.1;
      letter-spacing: -0.04em;
      color: #130f49;
      margin-bottom: 56px;
    }
    .mail-wrap {
      @include flex-center();
      align-items: center;
      svg {
        width: 48px;
        height: auto;
        margin-right: 10px;
      }
      a {
        font-size: 28px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: -0.03em;
        color: #b4b2c5;
      }
    }
  }
}

@media (max-width: 767px) {
  .instagram-mock-wrapper {
    .mobile-mockup {
      width: 300px;
      height: 597px;
      .status-bar {
        width: 263px;
        height: 28px;
        padding-top: 2px;
        padding-right: 10px;
        margin-top: 14px;
      }
    }
    .content {
      width: 264px;
      height: 514px;
      padding: 0 12px 0;
      margin: 40px auto;
    }
  }
}
