@import "./../styles/variables";

.projects {
  position: relative;
  .projects-container {
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    transition: 0.5s all;
  }
  .project-cards {
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(2, 320px);
    grid-gap: 70px;
    .project-card {
      position: relative;
      min-height: 350px;
      background: #000a;
      border-radius: 16px;
      box-shadow: inset 5px 5px 20px rgba(255, 255, 255, 0.1);
      transition: 0.2s;
      overflow: hidden;
      filter: brightness(0.8) saturate(0.8);
      padding: 24px;
      .shine {
        position: absolute;
        width: 1300px;
        height: 100px;
        background: linear-gradient(transparent, #c8c8c81a, transparent);
        transform: rotate(30deg);
        margin-left: -140px;
      }
      .open-link {
        position: absolute;
        right: 20px;
        width: 20px;
        height: 20px;
        svg {
          fill: #fff;
        }
      }
      .project-title {
        font-size: 26px;
        color: #fff;
        margin: 30px 0;
        font-family: Spacegrotesk, sans-serif;
        font-weight: 700;
        &::first-letter {
          font-size: 42px;
        }
      }
      .project-description {
        color: #bdbdbd;
      }
      .tech-icons {
        position: absolute;
        bottom: 24px;
        display: flex;
        svg {
          width: 30px;
          height: 30px;
          margin: 20px 10px 0 0;
        }
      }
      &:hover {
        filter: brightness(1.5) saturate(2.5);
        .shine {
          animation: shine 6s ease-in-out infinite;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .projects .projects-container {
    flex-direction: column;
    padding-bottom: 40px;
    .project-cards {
      grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
      grid-gap: 50px;
      .project-card {
        min-height: 320px;
        filter: brightness(1.2) saturate(1.5);
        .shine {
          animation: shine 6s ease-in-out infinite;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .projects .projects-container .project-cards {
    grid-gap: 30px;
    .project-card {
      min-height: 300px;
      .project-title {
        font-size: 22px;
        margin: 20px 0;
        &::first-letter {
          font-size: 36px;
        }
      }
      .project-description {
        color: #7a7a8c;
      }
    }
  }
}
@keyframes shine {
  0%,
  100% {
    margin-top: -100px;
  }
  50% {
    margin-top: 800px;
  }
}
