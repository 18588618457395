@import "./../styles/variables";
@import "./../styles/mixins";

.about-section {
  position: relative;
  .about-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 40px;
  }
  .content-wrap {
    position: relative;
    width: 100%;
    @include flex-center(column);
    margin: auto;
  }
  .footer-nav-wrap {
    display: flex;
    justify-content: space-between;
    .logo-wrapper {
      width: 60px;
      height: 60px;
      @include flex-center();
      border-radius: 100px;
      box-shadow: 4px 30px 55px 0 rgba(88, 207, 255, 0.2);
      padding: 15px;
    }
    .footer-nav-menu {
      @include flex-center();
      a {
        @include flex-center();
        margin: 6px 22px;
        &:last-child {
          margin-right: 0;
        }
        @include social-icon();
      }
    }
  }
}

@media (min-width: 1025px) {
  .about-section .footer-nav-wrap {
    @include nav-menu-hover-effect;
  }
}

@media (max-width: 767px) {
  .about-section {
    .about-container {
      padding-top: 40px;
      padding-bottom: 20px;
    }
    .footer-nav-wrap {
      margin-top: 20px;
      .logo-wrapper {
        box-shadow: none;
      }
      .footer-nav-menu a svg {
        width: 20px;
      }
    }
  }
}
