@import "./../styles/variables";
@import "./../styles/mixins";

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  min-height: 75px;
  display: flex;
  align-items: center;
  background-color: #000;
  // box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  z-index: 1000;
  padding: 40px 0 20px;
}

.navigation-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo-container {
    a {
      max-width: 60px;
      display: block;
      z-index: 2;
      margin: -10px 0;
    }
    .mobile-nav-button {
      display: none;
    }
  }
  .nav-menu {
    @include flex-center();
    a {
      @include flex-center();
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.02em;
      color: $inactiveMenuColor;
      transition: all 0.2s ease;
      margin: 6px 24px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        color: $activeMenuColor;
      }
      @include social-icon;
    }
  }
}

@media (min-width: 1025px) {
  .navigation-container .nav-menu {
    @include nav-menu-hover-effect;
  }
}

@media (max-width: 767px) {
  .navbar {
    position: absolute;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
  .navigation-container {
    flex-direction: column;
    justify-content: flex-end;
    .nav-menu {
      position: absolute;
      width: 100%;
      display: inline;
      flex-direction: column;
      background-color: #000;
      transform: translateY(-80px);
      transition: 0.5s all;
      a {
        display: block;
        padding: 24px 22px;
        margin: 0;
        &:not([href^="#"]) {
          filter: none;
          display: inline-flex;
          padding: 0;
          margin: 24px 22px;
        }
      }
    }
    .logo-container {
      a {
        margin: 0;
      }
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: #000;
      z-index: 10;
      transition: 0.5s all;
      padding: 20px 0 0;
      .mobile-nav-button {
        width: 60px;
        height: 60px;
        @include flex-center;
        border-radius: 20px;
        color: $activeMenuColor;
        background-color: #000;
        border: 1px solid $activeMenuColor;
        font-size: 22px;
        cursor: pointer;
        z-index: 5;
      }
    }
  }
  .navbar.show {
    .nav-menu {
      transform: translateY(100%);
    }
    .logo-container {
      background-color: #000;
      .mobile-nav-button {
        background-color: $activeMenuColor;
        color: #fff;
      }
    }
  }
}
